<template>
<loader v-bind="{ loading }">
    <columns>
        <column class="is-3">
            <div class="is-flex mb-3 is-justify-content-space-between is-align-items-center">
                <h4 class="title is-4 is-marginless">Summary</h4>
                <outcome-tag 
                    :status="equipment.status"
                />
            </div>
            <p class="is-flex is-justify-content-space-between">
                <span>Linked Code</span>
                <span>{{ equipment.code.value }}</span>
            </p>
            <p class="is-flex is-justify-content-space-between">
                <span>Classification</span>
                <span>{{ equipment.classification.name }}</span>
            </p>
            <p class="is-flex is-justify-content-space-between">
                <span>Customer</span>
                <router-link :to="{
                    name: 'customer-manager',
                    params: {
                        customer: equipment.location.customer.uuid
                    }
                }">{{ equipment.location.customer.name }}</router-link>
            </p>
            <p class="is-flex is-justify-content-space-between">
                <span>Location</span>
                <router-link :to="{
                    name: 'location-manager',
                    params: {
                        location: equipment.location.uuid
                    }
                }">{{ equipment.location.name }}</router-link>
            </p>
            <p 
                v-for="property in this.equipment.classification.meta" :key="property.uuid"
                class="is-flex is-justify-content-space-between">
                <span>{{ property.name }}</span>
                <span>{{ mapPropertyValue(property.uuid) }}</span>
            </p>
        </column>
        <column>
            <div v-if="overview.latest_checklist && overview.latest_checklist.id && overview.latest_checklist.geo_location">
                <h4 class="title is-4">Last Known Location</h4>
                <h6 class="subtitle is-6 has-text-grey">
                    lat: {{ overview.latest_checklist.geo_location.latitude }}, 
                    lng {{ overview.latest_checklist.geo_location.longitude }} accurate to {{ overview.latest_checklist.geo_location.accuracy.toFixed(2) }} meters
                </h6>
                <GmapMap
                    :center="{lat: overview.latest_checklist.geo_location.latitude, lng: overview.latest_checklist.geo_location.longitude}"
                    :zoom="16"
                    map-type-id="terrain"
                    style="width: 100%; height: 300px">
                    <GmapMarker ref="myMarker" :position="google && new google.maps.LatLng(overview.latest_checklist.geo_location.latitude, overview.latest_checklist.geo_location.longitude)" />
                </GmapMap>
            </div>
            <div v-else class="p-3 has-text-centered">
                There is no last known location for this equipment.
            </div>
        </column>
    </columns>  
</loader> 
</template>
<script>
import { mapGetters } from 'vuex'
import { getGoogleMapsAPI } from 'gmap-vue'

export default {

    data: () => ({
        loading: true
    }),

    async beforeCreate() {
        await this.$store.dispatch('equipment/loadOverview', this.$route.params.equipment)
        this.loading = false
    },

    methods: {
        mapPropertyValue(uuid) {
            const property = this.equipment.properties.filter(meta => meta.uuid === uuid)[0]
            return property ? property.value : null
        }
    },
    
    computed: {
        ...mapGetters('equipment', [
            'equipment',
            'overview'
        ]),
        google: getGoogleMapsAPI,
    }

}
</script>