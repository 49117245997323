var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',{staticClass:"is-3"},[_c('div',{staticClass:"is-flex mb-3 is-justify-content-space-between is-align-items-center"},[_c('h4',{staticClass:"title is-4 is-marginless"},[_vm._v("Summary")]),_c('outcome-tag',{attrs:{"status":_vm.equipment.status}})],1),_c('p',{staticClass:"is-flex is-justify-content-space-between"},[_c('span',[_vm._v("Linked Code")]),_c('span',[_vm._v(_vm._s(_vm.equipment.code.value))])]),_c('p',{staticClass:"is-flex is-justify-content-space-between"},[_c('span',[_vm._v("Classification")]),_c('span',[_vm._v(_vm._s(_vm.equipment.classification.name))])]),_c('p',{staticClass:"is-flex is-justify-content-space-between"},[_c('span',[_vm._v("Customer")]),_c('router-link',{attrs:{"to":{
                    name: 'customer-manager',
                    params: {
                        customer: _vm.equipment.location.customer.uuid
                    }
                }}},[_vm._v(_vm._s(_vm.equipment.location.customer.name))])],1),_c('p',{staticClass:"is-flex is-justify-content-space-between"},[_c('span',[_vm._v("Location")]),_c('router-link',{attrs:{"to":{
                    name: 'location-manager',
                    params: {
                        location: _vm.equipment.location.uuid
                    }
                }}},[_vm._v(_vm._s(_vm.equipment.location.name))])],1),_vm._l((this.equipment.classification.meta),function(property){return _c('p',{key:property.uuid,staticClass:"is-flex is-justify-content-space-between"},[_c('span',[_vm._v(_vm._s(property.name))]),_c('span',[_vm._v(_vm._s(_vm.mapPropertyValue(property.uuid)))])])})],2),_c('column',[(_vm.overview.latest_checklist && _vm.overview.latest_checklist.id && _vm.overview.latest_checklist.geo_location)?_c('div',[_c('h4',{staticClass:"title is-4"},[_vm._v("Last Known Location")]),_c('h6',{staticClass:"subtitle is-6 has-text-grey"},[_vm._v(" lat: "+_vm._s(_vm.overview.latest_checklist.geo_location.latitude)+", lng "+_vm._s(_vm.overview.latest_checklist.geo_location.longitude)+" accurate to "+_vm._s(_vm.overview.latest_checklist.geo_location.accuracy.toFixed(2))+" meters ")]),_c('GmapMap',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":{lat: _vm.overview.latest_checklist.geo_location.latitude, lng: _vm.overview.latest_checklist.geo_location.longitude},"zoom":16,"map-type-id":"terrain"}},[_c('GmapMarker',{ref:"myMarker",attrs:{"position":_vm.google && new _vm.google.maps.LatLng(_vm.overview.latest_checklist.geo_location.latitude, _vm.overview.latest_checklist.geo_location.longitude)}})],1)],1):_c('div',{staticClass:"p-3 has-text-centered"},[_vm._v(" There is no last known location for this equipment. ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }